import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de utilização de tráfego</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de utilização de tráfego</H5>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph>A todos os clientes de Colocation, Servidores Sedicados e VPS será provido acesso aos gráficos de utilização de banda, atualizados em um intervalo de 15 minutos. Estes gráficos estão localizados na área de cliente, denominada CORE. Toda cobrança de tráfego é exclusivamente calculada baseada nos gráficos providos no CORE.</Paragraph>
                  <Paragraph>Para calcular a utilização de tráfego, a HostDime utiliza o método Percentil 95: número que define a utilização de um circuito de dados com tráfego IP, obtido através de medidas de tráfego, efetuadas em frequência de 15 minutos, sendo considerado o maior valor, depois de desconsiderados 5% (cinco por cento) das maiores medidas obtidas em um determinado período de amostragem. Neste método, obtem-se o percentil 95 das medidas de tráfego Internet coletadas em intervalos constantes de 15 (quinze) minutos ao longo das 24 (vinte e quatro) horas do dia e dos 30 (trinta) dias do mês. O percentil 95 será calculado para o tráfego entrante e também para o sainte individualmente, tomando-se como valor representativo final para aferição da utilização do tráfego a soma dos dois valores. O valor a ser considerado será o menor percentil 95 mensal, obtido durante o período de avaliação.</Paragraph>
                  <Paragraph>Você será cobrado pelo que foi utilizado do excedente utilizando o método 95% de medição. De acordo com nossos termos, você é responsável por acompanhar a utilização através de sua área de cliente e aceita pagar este tráfego excedente pelo preço de R$ 75 (setenta e cinco reais) para servidores hospedados no nosso Data Center em Orlando-USA e R$ 150 (cento e cinquenta reais) para servidores hospedados no nosso Data Center no Brasil por cada 1Mbps excedente de tráfego contínuo. Nosso departamento comercial pode sugerir, de acordo com as informações passadas por você, qual seria o tráfego estimado para seu serviço, no entanto trata-se de uma mera estimativa e não pode ser utilizada como argumento para não cumprimento de obrigação de pagar por eventuais excedentes de tráfego.</Paragraph>
                  <Paragraph>Exemplo (Servidor nos EUA):<br /> Tráfego contratado: 4.5 mbps<br /> Tráfego utilizado em um mês = 5.0 mbps (Baseado no método 95%)<br /> Tráfego excedido = 5.0 mbps - 4.5 mbps = 0.5 mbps<br /> 0.5 mbps x R$ 75 = R$37,50</Paragraph>
                  <Paragraph>Se você prevê alta utilização de tráfego, por favor contate nosso departamento de vendas para maiores informações de disponibilidade de planos de alta utilização de tráfego por melhores preços.</Paragraph>
                  <Paragraph>Observação: O uso contínuo de 1Mbps equivale a 320GB de dados transferidos em um período de 30 dias</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de utilização de tráfego' />
      <PageTemplate
        title='Política de utilização de tráfego'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
